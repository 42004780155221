@import 'styles/extends/_generics';

.activities-log {
    padding: $modal-padding;
    padding-top: 0;

    .frame {
        border-bottom: 1px solid $bottom-border-gray;
        padding-bottom: 30px;
    }

    &:last-child {
        border: none;
    }

    .no-result {
        padding: 0;
    }

    &--item {
        color: $black;
        border-bottom: 1px solid $bottom-border-gray;
        padding: $modal-padding 0;
        &:nth-last-child(2) {
            border-bottom: none;
        }

        &__avatar {
            width: 40px;
            height: 40px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }

        &__name {
            @extend %f-medium;
            display: inline-block;
            width: 15%;
            @extend %ellipse-text
        }

        &__content {
            display: inline-block;
            max-width: 55%;
            @extend %ellipse-text;
        }
    }

    &--table {
        .tr__table {
            box-shadow: none;
            background-color: transparent;

            &--header {
                padding-bottom: 10px;
                .table-header {
                    .column {
                        width: 200px;
                        flex: inherit;
                        
                        &.field {
                            flex-grow: 2;
                        }
                        &.original-value {
                            flex-grow: 1;
                        }
                        &.new-value {
                            flex-grow: 1;
                        }
                    }
                }
            }
    
            &--row {
                align-items: initial !important;
                &__column {
                    padding: 15px;
                    width: 200px;
                    min-width: auto;
                    color: $black;
                    border-right: 1px solid $multiselect-gray;
                    h6 {
                        display: inline-block;
                        width: 100%;
                        @extend %ellipse-text
                    }
                    
                    &:nth-child(1) {
                        flex-grow: 2;
                    }

                    &:nth-child(2) {
                        flex-grow: 1;
                    }

                    &:nth-child(3) {
                        flex-grow: 1;
                    }
            
                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }
    }
}