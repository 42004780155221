@import 'styles/extends/_generics';

$space: 20px;
$box-radius: 5px;
$header-height: 77px;

.tr__shipment-issues {
    @extend %card-box;

    &--header {
        padding: $content-padding;
        background-color: $button-hover-gray;
        border-radius: $box-radius;
        min-height: $header-height;
    }

    .tr__tabs {
        position: relative;
        margin-top: -$space;
        &--nav {
            position: absolute;
            left: $space;
            top: -72px;
            width: $tabs-size;
            z-index: 1;

            ul {
                li {
                    width: 50%;
                    padding: 8px 10px;
                    margin: 0;
                    background-color: $white;

                    &.active {
                        background-color: $main-blue;
                        border-right-color: $main-blue;
                    }
                }
            }
        }

        &--content {
            margin-top: $space * 2;
        }
    }

    .tr__table {
        box-shadow: none;
        background: transparent;
        overflow: inherit;
        &--row {
            border-bottom-color: $bottom-border-gray;
            &.done {
                position: relative;
                z-index: 0;

                &:after {
                    content: '';
                    width: calc(100% + 20px);
                    height: 80%;
                    background-color: $main-pink !important;
                    @include opacity(0.05);
                    @include centered-position(true, true);
                    border-radius: 3px;
                    z-index: 0;
                }
                .tr__table--row__column {
                    position: relative;
                    z-index: 1;
                }
            }

            &:last-child {
                border: none;
                &:after {
                    display: flex;
                }
            }

            &__column {
                flex: inherit;
                width: 200px;
                min-width: auto !important;

                &:first-child {
                    flex-grow: 1;
                    padding-left: 0;
                }

                &:nth-child(2) {
                    width: 250px;
                    padding-right: 0;

                    .tr__organization-members-avatar {
                        width: 100%;
                        > p {
                            display: none;
                        }
                    }
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

.issue-title {
    i {
        color: $light-blue;
    }

    .h6 {
        color: $base-text-color;
    }

    p {
        color: $label-gray;
        font-size: $tiny-size !important;
    }

    &.active {
        i {
            color: $main-pink;
        }

        .h6 {
            color: $black;
            @extend %f-medium;
        }
    }
}

.issue-date {
    p {
        color: $label-gray;
        font-size: $tiny-size !important;
        &.open {
            color: $main-pink;
        }
    }
}
