@import 'styles/helpers/_variables';

// Headings classes
.h1 {
    font-size: $h1;
}

.h2 {
    font-size: $h2;
}

.h3 {
    font-size: $h3;
}

.h4 {
    font-size: $h4;
}

.h5 {
    font-size: $h5;
}

.h6 {
    font-size: $h6;
}