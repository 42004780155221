@import 'styles/extends/_generics';

.tr__vessel-schedule-map {
    position: relative;
    
    .tr__search-forms {
        position: absolute;
        z-index: 10;
        top: 30px;
        left: 30px;
    }
}
