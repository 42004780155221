@import 'styles/extends/_generics';

$remove-icon-size: 20px;

.tr__verify-details-form--group {
    .frame {
        border: 1px solid $bottom-border-gray;
        border-radius: 5px;
        position: relative;
        background: $light-gray;
        .tr__field-input {
            background: $light-gray;
        }
        ::placeholder { 
            color: $base-text-color;
        }

        .remove-card {
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(50%, -50%);
            width: $remove-icon-size;
            height: $remove-icon-size;   
            border-radius: $remove-icon-size; 
            background-color: $error-red;
            color: $white;
            @include flex(row, flex-end, center);
            font-size: $h1;
            line-height:  1;
        }
        .card {
            padding: 10px !important;
        }
    }
}