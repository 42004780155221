@import 'styles/extends/_generics';

.tr__shipment-info {
    @extend %card-box;
    padding: 0;
    // background: $bg-grey;

    .tr__details-box {
        &:nth-last-child(2) {
            &:after {
                left: -$modal-padding;
                width: calc(100% + #{$modal-padding * 2});
            }
        }
    }

    &--content {
        padding: $modal-padding;
    }

    &--footer {
        @extend %section-footer;
    }
    .sub-card {
        padding: 15px;
        .card-add-new {
            border: 1px solid #D8D8D8;
            border-radius: 5px;
            position: relative;
            background: #f4f4f4;
            padding: 0 15px;
            margin-bottom: 15px;
            padding-bottom: 15px;
        }
    }
}