@import 'styles/extends/_generics';

.tr__organization-title {
    padding: 0;
    p {
        flex: 1;
        margin-left: 20px;
    }
    span {
        flex: 1;
    }
    .tr__details-table-header {
        width: 100%;
    }
}

.tr__organization-details-form {
    .file-upload {
        display: flex;
    }

    .organization-name {
        @include flex(column, inherit, flex-end);
        .tr__field > label {
            font-size: $h6;
        }
        .tr__field-input, .label {
            font-size: $medium-size;
        }
    }
    
    .tr__file-uploader {
        width: auto;

        &--wrapper {
            flex-direction: column;
            align-items: center;

            .tr__link {
                margin-top: 15px;
                margin-left: 0;
            }
        }
    }

    &--contact {
        margin-top: 30px;
    }

    &.modal-form {
        .tr__file-uploader {
            width: 100%;

            &--wrapper {
                flex-direction: row;
                align-items: flex-start;

                .tr__link {
                    margin-top: 10px;
                    margin-left: 15px;
                }
            }
        }
    }
}