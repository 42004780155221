@import 'styles/extends/_generics';

$toast-message-width: 400px;

.tr__toast-message {
    pointer-events: none;
    z-index: 1200;
    .tr__modal--wrapper {
        .tr__modal--content {
            &--wrapper {
               height: 100%; 
            }
            background-color:$white !important;
            float: right;
            height: 75px;
            margin-right: 30px;
            padding: $modal-padding - 10px;
            padding-right: $modal-padding * 2.5;
            line-height: 1.5;
            width: $toast-message-width;
            box-shadow: $wide-shadow;
            pointer-events: all;
            border: 1px solid transparent;
            p {
                display: block;
                // line-break: anywhere;
                word-wrap: break-word;
            }

            strong {
                @extend %f-medium;
            }
            i {
                position: absolute;
                right: 25px;
                top: 25px;
                font-size: 20px;
                cursor: pointer;
                color: $dark-grey;
            }
            i:hover {
                -ms-transform: scale(1.5);
                -webkit-transform: scale(1.3);
                transform: scale(1.5); 
                transition: transform .3s ease-in-out;
                opacity: .6;
            }
        }
        .error-toast-message {
            display: flex;
            align-items: center;
            height: inherit;
            .content {
                cursor: pointer;
                padding-left: 30px;
            }
        }
    }

    .line-toast-message {
        position: absolute;
        top: 6px;
        height: 60px;
        width: 5px;
        background: $pending-status-yellow;
        border-radius: 10px;
    }
}