@import 'styles/extends/_generics';

.tr__billing-details-form {
    margin-top: 10px;
    margin-bottom: 50px;
    position: relative;

    .expired-date {
        &--input {
            .tr__field--wrapper {
                cursor: pointer;
                
                .tr__field-input {
                    pointer-events: none;
                    user-select: none;
                }
            }
        }
    }

    .tr__field {
        &.disabled {
            .tr__field-input {
                background-color: $upload-zone-gray;
            }
        }
    }

    &__card {
        background-color: $upload-zone-gray;
        border-radius: 5px;
        padding: $modal-padding;
        position: relative;

        img {
            height: 45px;
        }
        span {
            font-size: $small-size;
        }

        .remove-card {
            position: absolute;
            top: -$remove-icon-size / 2;
            right: -$remove-icon-size / 2;
            width: $remove-icon-size;
            height: $remove-icon-size;   
            border-radius: $remove-icon-size; 
            background-color: $error-red;
            color: $white;
            @include flex(row, flex-end, center);
            font-size: $h1;
            line-height: 1;
        }

        .tr__field-input {
            background-color: transparent;
        }
    }

    .add-card {
        height: 100%;
        border: 1px dashed $border-gray;
        background-color: $white;
        @include flex(column, center, center);
        border-radius: 5px;
        @extend %clickable;
        user-select: none;
        padding: $modal-padding;

        > i {
            font-size: $h1 * 2;
            color: $label-gray;
        }

        p {
            color: $label-gray;

            i {
                font-size: $extra-tiny-size;
            }
        }

        &.small {
            padding: $content-padding;

            i, p {
                @include opacity(0.5);
            }
        }
    }

    .tr__datepicker {
        .react-datepicker {
            &-popper {
                margin-top: 45px;
                margin-left: -135px;
            }
        }
    }
}