@import 'styles/extends/_generics';
$width-selected-item: 150px;

.tr__issue-modal {
    .tr__dropdown {
        .tr__field-input {
            @include placeholder() {
                font-weight: normal;
            }
        }
    }
    .tr__multiselect > label {
        margin: 0;
        .h2 {
            font-size: 12px;
            padding-bottom: 10px;
        }
        
    }
    .tr__multiselect--wrapper {
        .tr__dropdown__label {
            .tr__selected-values--item {
                width: $width-selected-item;
                h6 {
                    width: 75%;
                    @extend %ellipse-text;
                }
            }
        }
    }
}