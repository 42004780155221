@import 'styles/extends/_generics';

.tr__latest-event {
    background-color: $light-gray;
    padding: 20px;
    border-radius: 3px;
    &--title {
        margin-bottom: 20px;
    }
    .icon-warning {
        cursor: pointer;
    }
    .step {
        &--icon {
            position: relative;
            i {
                @include flex(row, center, center);
                width: $icon-size;
                height: $icon-size;
                border-radius: $icon-size;
                background-color: $main-blue;
                color: $white;
                font-size: $tiny-size;
                position: relative;
                z-index: 1;
            }

            &:after {
                content: '';
                @include centered-position(true, false);
                top: 0;
                height: 100%;
                width: 1px;
                border-left: 1px dashed $bottom-border-gray;
            }
            
        }

        &--info {
            flex-grow: 1;
            padding: 0 10px;

            > p {
                font-size: $small-size;
                margin-top: -5px;
            }

            
            .disabled {
                color: $placeholder-gray;
            }
            
            &__times {
                &--item {
                    padding: 10px 0;
                    border-bottom: 1px solid $bottom-border-gray;

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        border-bottom: 0;
                        padding-bottom: 0;
                    }

                    .h6 {
                        &:nth-child(2) {
                            color: $placeholder-gray;
                        }
                    }
                }
            }
        }
    }
}