@import 'styles/extends/_generics';

.doc--content {
    .tr__document-details--title{
        margin: 15px 0;
    }
    .jss1921 {
        margin: 24px 0;
    }
    .rowName {
        margin: 12px 0;
    }
}
