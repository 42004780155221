@import 'styles/extends/_generics';

.tr--preview-header {
    .title-header {
        h2, h6 {
            display: inline-block;
            width: 100%;
            @extend %ellipse-text
        }
    }

    .tr__organization-members-avatar {
        p {
            width: 85%;
            @extend %ellipse-text
        }
    }

    .tr__template-details {
        &--top-header {
           background-color: $button-hover-gray; 
           padding: 10px 15px;
           border-radius: 3px;
           display: inline-block;
           width: 100%;
           @extend %ellipse-text
        }
        .tr__template-details--box {
            min-height: 0px;
            .tr__template-task-item--table {
                    position: relative;
                    .table--header {
                        padding: 10px 0 20px 0;
                        @extend %task-table-row;
                        @extend %border-double-line;
                        color: $main-blue;
                        margin-bottom: 1px;
                    }
            }
            .table--body {
                .column {
                    width: 200px;
                    flex: inherit;

                    h4 {
                        width: 100%;
                        span {
                            display: inline-block;
                            width: 100%;
                            @extend %ellipse-text  
                        }
                    }
                    
                    .tr__datepicker {
                        position: relative;
                        flex-grow: 1;
        
                        .tr__field-input {
                            border: none;
                            border-radius: 0;
                            padding: 15px;
                            cursor: pointer;
                        }
        
                        .tr__badge {
                            @include centered-position(false, true);
                        }
                    }
        
                    &.partner {
                        padding: 10px 15px;
        
                        .partners {
                            font-size: $h1;
                            width: 100%;
                            @include flex(row, center, space-between);
                            i {
                                &:last-child {
                                    margin-right: 0;
                                }
                            }
        
                            span {
                                font-size: $base-size;
                            }
                        }
                    }
        
                    &.assignee {
                        padding: 10px 15px;
        
                        .tr__collaborators {
                            width: 100%;
                            justify-content: space-between;
                        }
                    }
        
                    &.name {
                        position: relative;
                        flex-grow: 1;
        
                        &:before {
                            content: '';
                            position: absolute;
                            bottom: -1px;
                            left: 0;
                            width: $icon-size;
                            height: 2px;
                            background-color: $white;
                        }
        
                        .h4 {
                            @include flex(row, center, center);
        
                            i {
                                margin-right: 10px;
                                width: $icon-size - 10px;
                                height: $icon-size - 10px;
                                border: 2px solid $bottom-border-gray;
                                background-color: $bottom-border-gray;
                                color: $white;
                                border-radius: $icon-size - 10px;
                                @include flex(row, center, center);
                                font-size: $base-size;
                            }
                        }
                    }
        
                    &.preview-date {
                        width: 145px;
                        > .tr__link {
                            font-size: $base-size;
                        }
                    }
                }
                .tr__badge {
                    padding: 6px 10px;
                    border-radius: 3px;
                    right: 15px;
                    background-color: $grey-button-date;
                    font-size: $tiny-size;
                    color: $white;
                }
            }
        }
    }
}
