@import 'styles/extends/_generics';

$modal-width: 400px;

.tr__edit-section {
    .tr__modal--content {
        width: $modal-width;

        &__footer {
            button {
                width: calc(100% / 2 - 10px);
            }
            
        }
    }

}