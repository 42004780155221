@import 'styles/extends/_generics';

.tr__spinner {
    @include flex(column, center, center);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $white;
    z-index: 1100;
    @extend %enable-transition;
    @extend %loading-icon;

    &.hidden {
        @include opacity(0);
        visibility: hidden;
        pointer-events: none;
    }

    &.transparent {
        background-color: rgba(0, 0, 0, 0.75);

        img {
            width: 300px;
        }

        &:after {
            border-color: $white;
            border-left-color: rgba(255, 255, 255, 0.3);
            border-right-color: rgba(255, 255, 255, 0.3);
        }
    }
}