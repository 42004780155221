@import 'styles/extends/_generics';
.tr__request-list {
    .tr__field--wrapper {
        .tr__field-input {
            border-bottom: 1px solid $border-gray;
            padding: 10px 0;
            width: 300px;
            background-color: transparent;
        }
        
        i {
            font-size: $large-size;
            color: $pink ;
            right: 5px;
        }
    }

    .tr__table {
        &--row {
            position: inherit;
            &__column {
                line-break: unset !important;
            }
        }
        .reference-no {
            h6 {
                width: 80%;
            }
        }
        .tr__link-task-pin-booking {
            border-radius: 50%;
            background-color: $main-pink;
            color: $white;
            font-size: 12px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
        }    
    }

    .tr__organization-members-avatar {
        > p {
            @extend %ellipse-text;
            max-width: 130px;
        }
    }
}