@import "styles/extends/_generics";

.tr__shipment-doc {
    @extend %card-box;
    padding: 0;

    &--header {
        @extend %border-double-line;
        padding: $modal-padding;
        .doc-actions {
            button {
                margin-right: 1rem;
                padding: 7px 15px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &--dropdown-assets {
        margin-left: 68%;
        width: 30%;
        margin-top: 15px;
        .manage-assets {
            background-color: #69a6ff;
            border: 1px solid $main-blue;
            border-radius: 5px;
            .tr__dropdown__label.select {
                border-bottom: none;
                background-color: #69a6ff;
                color: $white;
                font-size: 14px;
                margin-left: 7px;
                margin-right: 5px;
                text-align: center;
            }
            .tr__dropdown__label .placeholder {
                color: $white;
            }
            .tr__dropdown__label i {
                font-size: 10px;
                color: $white;
            }
            .tr__dropdown__items {
                z-index: 5 !important;
                background-color: #69a6ff;
                color: $white;
                width: 100%;
                .tr__dropdown__item {
                    padding-top: 10px;
                    h6 {
                        text-align: center;
                    }
                }
            }
        }
        .view-endosementchain {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            .tr__button {
                border: 1px solid $main-blue;
                color: #69a6ff;
                width: 48%;
            }
        }
    }

    &--body {
        padding: 1rem;
        height: 84vh;
        overflow: auto;
        .doc--content {
            width: calc(100% - 500px);
            background-color: $white;
            height: 100%;
        }
        .doc--comment {
            overflow: hidden;
            overflow-y: scroll;
            margin-left: 20px;
            width: 520px;
            background-color: #e9e9e9;
            @extend %card-box;
            height: 100%;
            padding: $modal-padding;
            .jss1921 {
                margin: 24px 0;
            }
            .rowName {
                margin: 12px 0;
            }
        }
    }
}
