@import 'styles/extends/_generics';

.tr__shipment-task-management {
    @extend %card-box;

    > .tr__collaborators {
        height: 40px;
        position: relative;
        z-index: 1;

        .tr__organization-members-avatar {
            > p {
                display: none;
            }
        }
    }

    .tr__tabs {
        position: relative;
        &--nav {
            position: absolute;
            right: 0;
            top: 0;
            width: $tabs-size / 1.1;
            z-index: 1;
            margin-top: -63px;

            ul {
                li {
                    width: 50%;
                    padding: 10px;
                    margin: 0;
                    background-color: $white;

                    &.active {
                        background-color: $main-blue;
                    }
                }
            }
        }

        &--content {
            position: relative;
            z-index: 1;
        }
    }

    .tr__task-pane {
        > .tr__tooltip {
            max-width: $progress-size;
            margin: -10px 0 20px auto;
        }
    }
}