@import 'styles/extends/_generics';

.tr__organization-teams {
    background-color: $white;
    .wrapper {
        @extend %tab-content-box;
        .tr__table {
            padding: 0 $modal-padding $modal-padding * 4 $modal-padding;
        }
        .tr__table--row {
            &__column {
                .tr__organization-members-avatar {
                    width: 100%;
                    justify-content: center;
                    margin-left: 0;
                    p {
                        display: none;
                    }
                }
            }
        }
    }
}