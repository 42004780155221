@import 'styles/extends/_generics';
$list-task-radius : 5px;
.tr__active-shipment-list {
    > .h2 {
        color: $label-gray;
    }

    .tr__pagination {
        margin-right: 75px;
        button {
            background-color: white;
        }
    }
}

.tr__link-task-list {
    background-color: $bottom-border-gray;
    padding: 20px;
    border-radius: $list-task-radius;
    position: relative;

    & > :nth-last-child(2) {
        margin: 0 !important;
    }
}

.tr__link-task-pin {
    @extend %pin-icon;
}