@import 'styles/extends/_generics';

.tr__info-cell {
    &--label {
        font-size: $small-size;
        color: $placeholder-gray;
        margin-bottom: 10px;
    }

    &--title {
        color: $light-blue;
    }

    &--content {
        p {
            line-height: 1.5;
        }
    }

    .tr__tooltip {
        &::before {
            left: 5% !important;
        }
    }
}