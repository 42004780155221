@import 'styles/extends/_generics';

.tr__document-header {
    .column {
        flex: 0 0 20%;
        &.name {
            flex: 0 0 25%;
        }
        &.partner {
            flex: 0 0 15%;
        }
        &.assignee {
            flex: 0 0 20%;
        }

        &.uploader {
            flex: 0 0 20%;
        }
    }

    &.document {
        .column {
            flex: 0 0 25%;

            &.name {
                flex: 0 0 30%;
            }
            &.uploader {
                flex: 0 0 20%;
            }

            &.type {
                flex: 0 0 20%;
            }

            &.tradeTrust {
                flex: 0 0 17%;
            }

            &.assignee {
                flex: 0 0 15%;
            }
        }
    }
}
