@import 'styles/extends/_generics';

.tr__container-form {
    @extend %search-form-box;

    &--group {
        margin-bottom: 20px;
    }
}

.route {
    position: relative;
    .tr__dropdown {
        margin-bottom: 10px;

        &__label {
            margin-left: 22px;
        }

        p.error {
            margin-left: 95px;
        }
    }
}

.route-icon {
    @include centered-position(false, true);
    width: 11px;
    border: 5px solid $white;
    margin-top: 10px;
    height: 70%;
    background-color: $bottom-border-gray;
    z-index: 0;

    i {
        position: absolute;
        left: -5px;
        font-size: $small-size;
        color: $label-gray;

        &:last-child {
            bottom: 0;
            color: $main-pink;
        }
    }
}