@import 'styles/extends/_generics';

$dot-size: 10px;
$btn-size: 40px;
$route-width: 240px;
$logo-width: 180px;
$logo-height: 80px;
$block-size: 180px;

.tr__vessel--search {
    border-radius: 5px;
    background-color: $white;
    box-shadow: $component-shadow;
    margin-bottom: 20px;
    overflow: hidden;

    &__info {
        .port {
            padding: $modal-padding;
            flex: 4;
            &__brand {
                border-radius: 3px;
                width: $logo-width;
                height: $logo-height;
                display: block;
                position: relative;
                overflow: hidden;
                border: 1px solid $bottom-border-gray;
                margin-right: 10px;

                img{
                    @include centered-position(true, true);
                    max-width: 100%;
                    max-height: 100%;
                    width: 70%;
                    top:30%;
                    height: 70%;
                    object-fit: contain;
                    padding: 8px 20px;
                }

                span {
                    position: absolute;
                    background-color: rgba($black, 0.5);
                    font-size: $small-size;
                    text-transform: uppercase;
                    color: $white;
                    padding: 7px 10px;
                    text-align: center;
                    width: 100%;
                    left: 0;
                    bottom: 0;
                    z-index: 1;
                }
            }
            &__title {
                p {
                    color: $label-gray;
                    font-size: $tiny-size;
                }
            }

            &__info {
                flex-grow: 1;
            }

            &__transhipping {
                position: relative;
                font-size: $small-size;
                @include flex(column, center, flex-start);
                width: $route-width;
                margin: 0 20px;

                &__label {
                    background-color: $white;
                    position: relative;
                }

                &__item {
                    @include flex(row, center, flex-end);
                    i {
                        font-size: $extra-tiny-size;
                        margin: 0 5px;
                    }

                    p {
                        color: $base-text-color;
                    }
                }

                &__dashed-line {
                    &:before {
                        content: '';
                        position: absolute;
                        top: $dot-size / 2 + 1px;
                        width: calc(100% - #{$dot-size * 3});
                        border-top: 1px dashed $placeholder-gray;
                        height: 1px;
                        overflow: hidden;
                        left: $dot-size * 1.5;
                    }
                }

                &:before, &:after {
                    content: '';
                    position: absolute;
                    top: 2px;
                    width: $dot-size;
                    height: $dot-size;
                    border-radius: $dot-size;
                    background-color: $bottom-border-gray;
                    z-index: 1;
                }

                &:before {
                    left: 0;
                }

                &:after {
                    right: 0;
                }

                .sub-title {
                    font-size: $tiny-size;
                    color: $label-gray;
                }
            }
        }
        .right__port{
            .block-column {
                // width: $block-size;
                padding: 0 20px;
            }
            &__date {
                border-left: 1px solid $bottom-border-gray;
                border-right: 1px solid $bottom-border-gray;
                width: 170px;

                p {
                    font-size: $tiny-size;
                    color: $label-gray;
                }
            }
            &__contact{
                background-color: $border-gray;
                padding: 13px 15px;
                border-radius: 3px;
                i{
                    color: $blue;
                    font-size: $h2;
                }
            }
            .tr__button {
                padding: 10px;
                width: $btn-size;
                height: $btn-size;
                @include flex(row, center, center);
                margin-right: 20px;
                
                &:last-child {
                    margin: 0;
                }

                i {
                    font-size: $h1;
                }
                &.block-button {
                    width: 120px;
                }
            }

            .hidden {
                position: absolute;
                @include opacity(0);
                pointer-events: none;
                user-select: none;
            }
        }
    }

    &__footer {
        background-color: $light-gray;

        .toggle-btn {
            color: $label-gray;
        }

        .tr__collapse {
            &--title {
                padding: 15px 20px;
            }
        }
        
        .content__view {
            padding: 10px;
            font-size: $small-size;

            &__header{
                position: relative;
                &__column {
                    min-width: 10%;
                    @extend %f-medium;
                    color: $placeholder-gray;
                    padding: 10px;
        
                    &:first-child {
                        text-align: left;
                        width: 15%;
                        padding-left: 30px;
                    }
                    &:nth-child(2){
                        width: 5%;
                    } 
                    &:nth-child(3){
                        width: 30%;
                    }

                    &:nth-child(4){
                        width: 20%;
                    }
                    
                    &:last-child {
                       width: 25%;
                    }
                }
            }
            &__row {
                position: relative;
                color: $base-text-color;
                background-color: $white;
                border-bottom: 1px solid $multiselect-gray;

                &:last-child {
                    border-bottom: 0;
                }
                
                &__column{
                    min-width: 10%;
                    line-height: 1.5;
                    @extend %f-medium;
                    padding: 10px;
        
                    &:first-child {
                        text-align: left;
                        width: 15%;
                        padding-left: 30px;
                    }
                    &:nth-child(2){
                        width: 5%;
                    } 
                    &:nth-child(3){
                        width: 30%;
                    }

                    &:nth-child(4){
                        width: 20%;
                    }
                    
                    &:last-child {
                       width: 25%;
                    }

                    .date {
                        font-size: $base-size;
                        color: $grey;
                    }
                }
            }
        }
    }
}