@import "styles/extends/_generics";
.preview_documents {
    z-index: 1090;
    .tr__modal--content {
        width: 900px;
        height: auto;
    }
    .tr__add-documents {
        background-color: $white;
        @extend %card-box;
        padding: 0;
        &--title {
            @extend %border-double-line;
            padding: $modal-padding;
        }
        .message {
            color: $pink;
        }
    }
    .page__button {
        cursor: pointer;
    }

    canvas {
        cursor: pointer;
    }

    .tr__modal--content--wrapper {
        flex-direction: row;
    }

    .preview {
        height: "700px";
        flex: 70%;
    }

    .signers {
        flex: 30%;
    }
}
