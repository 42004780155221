.tr__edit-section {
    .tr__modal--content__footer {
        flex-direction: column;
        .tr__button {
            width: 100%;
        }

        > p {
            order: 1;
            margin-top: 5px;
        }
    }
}  
