.tr__template-task {
    &-list {
        .tr__template-task-item {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}