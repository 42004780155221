@import 'styles/extends/_generics';

$panel-width: 320px;

.tr__shipment-filter {
    z-index: 9;

    .tr__modal--content {
        width: $panel-width;
        margin: 0 0 auto auto;
        transform: none;
        border-radius: 0 !important;
        min-height: calc(100% - #{$header-height});
        margin-top: $header-height;

        .tr__dropdown__item {
            h6 {
                margin-right: 15px;
                overflow: hidden;
                white-space: normal;
                text-overflow: ellipsis;
                max-width: 250px;
            }
        }
        
        &__title {
            border-radius: 0;
        }

        .tr__modal--close {
            display: none;
        }
    }

    &.hidden {
        .tr__modal--content {
            transform: translate($panel-width, 0);
        }
    }

    &--group {
        @extend %border-double-line;

        &.last {
            border: none;

            &:after {
                display: none;
            }
        }

        .route-icon {
            margin-top: -8px;
            height: 52%;
        }

        .tr__multiselect {
            @extend %multiselect-filter;
        }
    }

    &--btns {
        .tr__button {
            flex: 0 0 calc(50% - 5px);
            margin-right: 10px;

            &:last-child {
                margin: 0;
            }
        }
    }

    .react-datepicker-popper {
        margin-left: -50px;
    }
}