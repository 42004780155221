@import 'styles/extends/_generics';

.tr__info-row {
    border-bottom: 1px solid $multiselect-gray;
    padding: $modal-padding;

    .tr__info-row {
        padding: 0;
        border-bottom: 0;
    }

    &--label {
        color: $light-blue;
    }
}