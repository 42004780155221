@import 'styles/extends/_generics';

.tr__template-task-header {
    .column {
        padding: $content-padding;
        flex: inherit;
        width: 200px;

        &.name {
            flex-grow: 1;
        }

        &.date {
            width: 165px;
        }
    }
}