@import 'styles/extends/_generics';

$modal-width: 1200px;

.tr__view-documents {
    z-index: 11;
    .tr__modal--content {
        width: $modal-width;
        .wrapper {
            @extend %card-box;
            .tr__table {
                box-shadow: none;
                background-color: transparent;
                overflow: visible;
        
                &--row {
                    align-items: initial !important;
                    .failed-generate {
                        background: #d8d8d8;
                        color: $label-gray;
                        border: none;
                        border-radius: 3px;
                        cursor: pointer;
                    }
                    &__column {
                        padding: 15px;
                        flex: 0 0 25%;
                        border-right: 1px solid $multiselect-gray;
        
                        &:nth-child(1) {
                            flex: 0 0 30%;
                            padding-left: 0;
                        }
        
                        &:nth-child(2) {
                            flex: 0 0 20%;
                        }
        
                        &:nth-child(3) {
                            flex: 0 0 20%;
                        }
        
                        &:nth-child(4) {
                            flex: 0 0 17%;
                        }
                
                        &:last-child {
                             flex: 0 0 15%;
                            border-right: 0;
                            .tr__button {
                                padding: 10px 15px;
                                margin: 0 10px;
                            }
                        }
        
                        .tr__view-documents--action {
                            width: 100%;
                            padding: 0 20px;
                            i {
                                font-size: $large-size;
                                color: $main-blue;
                            }
                            .tr__tooltip--popup {
                                left: auto;
                                right: 0;
                            }
                        }
        
                        .tr__doc-title {
                            width: 100%;
                        }
                        .tr__doc-date {
                            p {
                                &:first-child {
                                    display: inline-block;
                                    line-break: anywhere;
                                } 
                            }
                        }
                        .tr__doc-tradeTrust button {
                            padding: 10px 15px;
                        }
                    }
                }
            }

            .tr__members-footer {
                padding-left: 0;
            }
        }
    }
}
