@import 'styles/extends/_generics';

.tr__result-header {
    .tr__link {
        &.secondary {
            font-size: $h4;
            > i {
                transform: rotate(-90deg);
            }
        }
    }
    
    .middle {
        > span {
            color: $label-gray;
        }

        .port {
            margin-left: 5px;

            i {
                font-size: $small-size;
                color: $main-pink;
                margin: 0 10px;
                display: inline-block;
                transform: rotateY(180deg);
            }
        }

        .tr__button {
            font-size: $small-size;
            padding: 5px 10px;
            @include flex(row, center, center);

            i {
                position: inherit;
                top: auto;
                left: auto;
                transform: none;
                margin-right: 5px;
            }
        }
    }

    .no-of-schedule {
        color: $label-gray;
    }
}
