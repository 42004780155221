@import 'styles/extends/_generics';

.tr__shipment-tag {
    padding: 5px;
    border-radius: 3px;
    font-size: $tiny-size;

    span {
        margin-right: 3px;

        &:last-child {
            margin: 0;
        }
    }
}