@import 'styles/extends/_generics';

.tr__filter--panel {
    border-radius: 3px;
    background-color: $white;
    box-shadow: $component-shadow;

    &__container {
        padding: $modal-padding;

        &__box {
            padding-bottom: 20px;
            @extend %border-double-line;

            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;

                &:after {
                    display: none;
                }
            }

            > h5 {
                font-size: $small-size;
            }
        }
    }
}
