@import 'styles/extends/_generics';

$max-height-box: 500px;

.tr__link-issue__modal {
    .tr__modal--content--wrapper {
        .link-issue__box {
            max-height: $max-height-box;
            overflow-y: auto;
            &-item {
                cursor: pointer;
                border-bottom: 1px dashed #D8D8D8;
                padding-bottom: 10px;
                &:hover{
                    opacity: .5;
                    transition: all .4s ease;
                } 
            }
        }

        i {
            margin-right: 10px;
            width: $icon-size - 10px;
            height: $icon-size - 10px;
            background-color: $pink;
            color: $white;
            border-radius: $icon-size - 10px;
            @include flex(row, center, center);
            font-size: $base-size + 5px;
        }
        .tr__link-issue {
            top: 0;
            right: 0;
            width: 20px;
            height: 20px;
            font-size: $tiny-size;
            position: relative;
        }
        .tr__link-issue__done {
            filter: grayscale(100%);
        }
    }
    
}
