@import 'styles/extends/_generics';

.tr__reminder{
    padding: 20px;

    &--header{
        position: relative;

        .tr__form-checkbox {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    .icon-times{
        @extend %close-icon;
    }
}