@import 'styles/extends/_generics';

$dot-size: 30px;
$step-min-width: 150px;

.tr__steps-breadcrum {
    .step {
        position: relative;
        padding: 20px 20px 0 20px;
        min-width: $step-min-width;
        counter-increment: section;

        &:last-child {
            padding-right: 0;

            &:after {
                display: none;
            }
        }

        &:before {
            content: counter(section);
            @include centered-position(true, false);
            top: 0;
            color: $white;
            width: $dot-size;
            height: $dot-size;
            background-color: $bottom-border-gray;
            border-radius: $dot-size;
            z-index: 1;
            @extend %enable-transition;
            @include flex(row, center, center);
            @extend %f-medium;
        }

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: $dot-size / 2;
            border-top: 1px dashed $border-gray;
            width: 100%;
        }

        p {
            font-size: $small-size;
            margin-top: 25px;
            color: $label-gray;
        }

        &.active {
            &:before {
                background-color: $main-blue;
            }

            p {
                color: $main-blue;
                @extend %f-medium;
            }
        }
        
        &.done {
            &:before {
                background-color: $main-blue;
            }

            p {
                color: $main-blue;
                @extend %f-medium;
            }

            &:after {
                border-top-color: $main-blue;
            }
        }
    }
}