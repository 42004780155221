@import 'styles/extends/_generics';

$btn-width: 180px;
$upload-icon-size: 30px;
$modal-width: 290px;

.tr__upload-documents {
    padding: $content-padding;
    background-color: $light-gray;
    border-radius: 5px;
    min-width: $modal-width;

    > h5 {
        color: $black;
    }

    &--note {
        i {
            *:before {
                color: $green !important;
            }
        }
    }

    .tr__file-uploader {
        &--zone {
            background-color: $white;
            margin-bottom: 15px;

            ul + .message {
                display: none !important;
            }
        }
    }

    &.list {
        padding: 0;

        .tr__file-uploader {
            padding: $content-padding;
            padding-bottom: 0;

            > label {
                font-size: $h5;
                @extend %f-medium;
                margin-bottom: 15px;
            }

            &--wrapper {
                margin-bottom: 15px;
            }
        }

        .tr__upload-documents--footer {
            background-color: $light-gray;
            padding: 10px 20px;
            .tr__link {
                color: $grey;
                @extend %f-medium;
            }

            .tr__button {
                padding: 10px 15px;
            }
        }
    }

    &--footer {
        .note {
            i {
                font-size: $h2;
                color: $main-pink;
                margin-right: 15px;
            }

            p {
                color: $label-gray;
            }
        }
        .tr__button {
            &.big-btn-size {
                width: $btn-width;
                padding: 15px;
            }
        }
    }

    &.modal {
        background-color: $white;
        @extend %box;
        overflow: hidden;

        .tr__file-uploader {
            &--zone {
                height: 75px;

                .message {
                    i {
                        font-size: $upload-icon-size;
                        margin-bottom: 8px;
                    }
    
                    p {
                        font-size: $tiny-size;
                        @extend %f-regular;
                    }
                }
            }
        }
    }
}