@import "styles/extends/_generics";

.tr__shipment-form {
	margin-bottom: 40px;

	.with-label {
		@include flex(row, flex-end, space-between);

		> .tr__field {
			flex-grow: 1;
		}

		.tr__dropdown {
			&__label {
				border: 0;
				padding-right: 20px;
				padding-left: 10px;
			}

			&.no-icon {
				.tr__dropdown__label {
					padding-right: 10px;
				}
			}
		}
	}

	.tr__vessel--search__info {
		.right__port {
			.block-column {
				padding: 40px;
			}
		}
		.border-column {
			border: 1px solid $bottom-border-gray;
			.port__brand {
				padding: 20px;
			}
		}
		.port__info {
			padding: 30px 0;
			border-left: 1px solid $bottom-border-gray;
			border-right: 1px solid $bottom-border-gray;
		}
	}
	.tr__vessel--search__footer {
		.content__view__header__column {
			&:first-child {
				width: 30%;
			}
			&:nth-child(2) {
				width: 20%;
			}
		}
		.content__view__row {
			margin-bottom: 10px;
			// div {
			.tr__info-cell {
				flex: 0 0 30% !important;
				margin-bottom: 10px;
			}
			.title {
				padding-left: 30px;
				border-bottom: 1px solid $multiselect-gray;
				.tr__info-cell--title {
					color: $placeholder-gray;
					padding-top: 10px;
				}
				.tr__info-cell--label {
					color: $base-text-color;
				}
				.date {
					color: $label-gray;
				}
			}
			// }
		}
	}

	.mapboxgl-ctrl {
		position: relative;
		z-index: 1;

		&-geocoder--icon,
		&-geocoder--pin-right,
		&-geocoder--input {
			display: none;
		}

		.suggestions {
			position: absolute;
			background-color: $white;
			border-radius: $border-radius;
			width: 100%;
			box-shadow: $component-shadow;
			margin-top: 5px;
			@extend %enable-transition;
			min-width: 200px;
			z-index: 1;
			max-height: 200px;
			overflow: hidden;
			overflow-y: visible;

			li {
				border: none;
				width: 100%;
				border-bottom: 1px solid $bottom-border-gray;
				padding: 15px 20px;
				cursor: pointer;
				@extend %enable-transition;

				&:hover {
					background-color: $light-gray;
				}

				&:last-child {
					border-bottom: 0;
				}

				.mapboxgl-ctrl-geocoder--suggestion {
					* {
						display: inline;
					}

					&-title {
						&:after {
							content: ",";
						}
					}
				}
			}
		}
	}

	.no-result {
		display: none;
	}

	.single-row {
		> label {
			border-top: 1px dashed $bottom-border-gray;
			font-size: $medium-size;
			display: block;
		}
	}
}
