@import 'styles/extends/_generics';

.doc--content {
    .doc-actions {
        button {
            margin-right: 1rem;
            padding: 7px 15px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}