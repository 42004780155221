@import 'styles/extends/_generics';

$edge-radius: 5px;
$upload-form-width: 320px;
$upload-icon-size: 25px;
$price-column-width: 150px;

.tr__fta-details {
    padding: $modal-padding;

    .tr__collapse {
        &--content {
            overflow: hidden !important;

            .tr__collapse {
                border-radius: 0;

                &:last-child {
                    border-bottom: 0;
                }
            }
        }

        &.active {
            > .tr__collapse--content {
                max-height: 3000px;
                .tr__collapse {
                    &.active {
                        > .tr__collapse--content {
                            max-height: 1000px;
                        }
                    }
                }
            }
        }
    }

    &--form {
        width: $upload-form-width;
        border: 1px solid $border-gray;
        border-radius: $edge-radius;
        overflow: hidden;

        .tr__upload-invoices-form {
            padding: $content-padding;

            .tr__file-uploader {
                &--zone {
                    .message {
                        i {
                            font-size: $upload-icon-size;
                        }

                        p {
                            font-size: $tiny-size;
                        }
                    }
                }
            }
        }

        &__footer {
            padding: $content-padding;
            background-color: $light-gray;
            p{
                color: $label-gray;
                text-align: center;
                font-size: 13px;
            }
        }
    }

    &--invoice {
        .invoice-header {
            @extend %border-double-line;

            .h1 {
                small {
                    font-size: $h3;
                    color: $label-gray;
                    margin-right: 5px;
                }

                span {
                    font-size: $large-size;
                }
            }
        }

        .invoice-table {
            > .h5 {
                color: $label-gray;
                span {
                    &:last-child {
                        color: $main-blue;
                        margin-left: 10px; 
                    }
                }
            }

            &--collapse {
                border-bottom: 1px solid $border-gray;
                .tr__collapse--title {
                    background-color: $white;
                    padding: 0;
                    @extend %enable-transition;
                }

                .tr__collapse--content {
                    .invoice-table--group {
                        .info {
                            padding-top: 0;
                        }

                        .price {
                            padding-top: 0;
                        }
                    }
                }

                &.active {
                    .tr__collapse--title {
                        background-color: transparent;
                    }
                }
            }
        }
        .invoice-table-documnents {
            color: $label-gray;
            .table--content {
                background-color: $light-gray;
                border-radius: $edge-radius;
                border: 1px solid $bottom-border-gray;
                margin-bottom: 40px;
                &--header {
                    padding: 17px;
                    color: $label-gray;
                    font-size: $small-size;
                }
                &--body {
                    .column {
                        padding: 10px 15px;
                        border-top: 1px dashed $bottom-border-gray;
                        &.name {
                            color: $black;
                            flex-grow: 1;
                            padding-left: 30px;
                        }
                        &.link {
                            font-size: $small-size;
                            flex: 0 0 160px;
                        }
                        &.action-button {
                            flex: 0 0 136px;
                            .tr__button {
                                padding: 7px 15px;
                                font-size: $small-size;
                            }
                        }
                    }
                }
            }
        }
    }

    .invoice-table--group {
        .info {
            flex: 0 0 calc(100% - #{$price-column-width});
            padding: $modal-padding;

            &--wrapper {
                border-top: 1px dashed $border-gray;
                border-bottom: 1px dashed $border-gray;
                padding: $modal-padding 0;

                .info-row {
                    line-height: 1.5;
                }
            }

            .details-links {
                padding-top: $modal-padding;

                .tr__link {
                    font-size: $base-size;
                }
            }

            .more-link {
                color: $placeholder-gray;
                flex: 0 0 68px;
            }
        }
        .price {
            flex: 0 0 $price-column-width;
            background-color: $collapse-blue;
            padding: $modal-padding;
            line-height: 1.5;
        }
    }

    // .good-title {
    //     > .h5 {
    //         span {
    //             &:first-child {
    //                 position: relative;
    //                 padding-right: 20px;

    //                 &:after {
    //                     content: '';
    //                     width: 4px;
    //                     height: 4px;
    //                     border-radius: 4px;
    //                     background-color: $label-gray;
    //                     @include centered-position(false, true);
    //                     right: 8px;
    //                 }
    //             }
    //         }
    //     }
    // }

    .info-row {
        @extend %f-regular;
        &--label {
            flex: 0 0 30%;
        }

        &--value {
            flex: 0 0 70%;
        }
    }

    .label {
        font-size: $small-size;
        color: $label-gray;
        @extend %f-regular;
    }
}