@import 'styles/extends/_generics';

.tr__access-permission {
    padding: 20px;

    .list{
        .tr__role{
            padding: 16px 0px;
            border-bottom: 1px solid $bottom-border-gray;
            
            .title {
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-right: 15px;
            }

            > span i {
                font-size: $medium-size;
            }

            .select {
                width: 200px;
            }
            .select.disabled{
                opacity: 1;
            }
        }
    }
}