@import 'styles/extends/_generics';

// input-range-slider
$input-range-slider-height: 1rem !default;
$input-range-slider-width: 1rem !default;
$input-range-slider-container-transition: left 0.1s ease-out !default;
$input-range-slider-active-transform: scale(1.3) !default;

// input-range-label
$input-range-label-position-bottom: -1.5rem !default;
$input-range-label-value-position-top: -2.2rem !default;

// input-range-track
$input-range-track-height: 0.3rem !default;
$input-range-track-transition: left 0.1s ease-out,
width 0.1s ease-out !default;

.input-range {
    height: $input-range-slider-height;
    position: relative;
    width: 100%;
    margin: 15px 0 22px 0;

    &__slider {
        appearance: none;
        background: $main-blue;
        border: $main-blue;
        border-radius: 100%;
        cursor: pointer;
        display: block;
        height: $input-range-slider-height;
        margin-left: $input-range-slider-width / -2;
        margin-top: $input-range-slider-height / -2 + $input-range-track-height / -2;
        outline: none;
        position: absolute;
        top: 50%;
        @extend %enable-transition;
        width: $input-range-slider-width;
        
        &:after {
            content: '';
            width: $input-range-slider-width / 2;
            height: $input-range-slider-height / 2;
            background-color: $white;
            top: $input-range-slider-height / 4;
            left: $input-range-slider-width / 4;
            border-radius: $input-range-slider-width / 2;
            display: block;
            position: absolute;
        }
    
        &:active {
            transform: $input-range-slider-active-transform;
        }
    
        &:focus {
            box-shadow: 0 0 0 5px transparentize($main-blue, 0.8);
        }
    
        .input-range--disabled & {
            background: $border-gray;
            border: 1px solid $border-gray;
            box-shadow: none;
            transform: none;
        }

        &-container {
            transition: $input-range-slider-container-transition;

            &:last-child {
                .input-range__slider {
                    // right: 0;
                }
            }

            .input-range__label {
                @include opacity(0);
                visibility: hidden;
                @extend %enable-transition;
            }

            &:hover {
                .input-range__label {
                    @include opacity(1);
                    visibility: visible;
                }
            }
        }
    }

    &__label {
        color: $main-blue;
        transform: translateZ(0);
        white-space: nowrap;
        color: $main-blue;
        @extend %f-medium;

        &--min,
        &--max {
            bottom: $input-range-label-position-bottom;
            position: absolute;
        }

        &--min {
            left: 0;
        }

        &--max {
            right: 0;
        }

        &--value {
            position: absolute;
            top: $input-range-label-value-position-top;
            left: 50%;
            transform: translate(-50%, 0);
            background-color: $base-text-color;
            color: $white;
            border-radius: 3px;
            padding: 3px;
            
            span {
                color: $white;
                font-size: $tiny-size;
            }
        }

        &-container {
            position: relative;
            @extend %f-regular;
            font-size: $small-size;
            color: $base-text-color;
        }
    }

    &__track {
        background: $light-gray;
        border-radius: $input-range-track-height;
        cursor: pointer;
        display: block;
        height: $input-range-track-height;
        position: relative;
        transition: $input-range-track-transition;
        margin: 0 $input-range-slider-width / 2;
    
        .input-range--disabled & {
            background: $grey;
        }

        &--background {
            left: 0;
            margin-top: -0.5 * $input-range-track-height;
            position: absolute;
            right: 0;
            top: 50%;
        }

        &--active {
            background: $main-blue;
        }
    }
}