@import 'styles/extends/_generics';

.tr__choose-freight-partner {
    .tr__multiselect {
        padding-bottom: $modal-padding;
        border-bottom: 1px dashed $bottom-border-gray;

        label {
            margin-left: 0;
        }
    }
    
    &--form {
        width: $content-width;
        margin: 40px auto 0;
    }
}