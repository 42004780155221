@import 'styles/extends/_generics';

.tr__map {
    &.mapbox {
        position: relative;
        > div {
            width: 100% !important;
            height: 100% !important;
        }

        canvas {
            outline: none;
        }

        .mapboxgl {
            &-popup {
                &-tip {
                    display: none;
                }
    
                &-content {
                    background-color: rgba($base-text-color, 0.7);
                    color: $white;
                    text-transform: uppercase;
                    padding: 10px;
                }
            }

            &-control-container {
                display: none;
            }

            .marker {
                display: block;
                border: none;
                border-radius: 50%;
                padding: 0;
            }
        }
    }
}