@import 'styles/extends/_generics';

.tr__template-header {
    .tr__button {
        padding: 5px 8px;
        border-radius: 0;
        background-color: $collapse-blue;
        border-radius: 3px;
    }

    .tr__template-header--title {
        width: 100%;
        div {
            width: 90%;
        }
    }
}