@import 'styles/extends/_generics';

$width-selected-item: 150px;

.tr__assign-modal {
    &--form,
    &--list {
        padding: $modal-padding;
    }

    &--form {
        border-bottom: 1px solid $border-gray;

        .tr__multiselect {
            > label {
                span {
                    line-break: anywhere;
                }
            }
        }
        .tr__multiselect--wrapper {
            .tr__dropdown__label {
                .tr__selected-values--item {
                    max-width: $width-selected-item;
                    > h6 {
                        width: 75%;
                        @extend %ellipse-text;
                    }
                }
            }

            .tr__partners {
                .tr__item-card {
                      max-width: calc(50% - 5px);  
                      .left-column {
                            justify-content: space-between;
                            width: 100%;
                            .info-member {
                                width: 90%;
                                h6 {
                                    width: 75%;
                                    @extend %ellipse-text;
                                }
                            }
                      }
                }
            }
        
        }
    }

    &--list {
        .list {
            img {
                width: 35px;
                height: 35px;
                border-radius: 35px;
                overflow: hidden;
            }
        }

        .tr__member--roles {
            .role-icons {
                width: 100%;
                justify-content: flex-end;
                > i{
                    color: $base-text-color;
                }
                
            }
        }
        .tr__assignee-list {
            .tr__member  {
                > i {
                    &.icon-users {
                            width: 35px;
                            height: 35px;
                            border-radius: 35px;
                            @include flex(row, center, center);
                            background-color: $bottom-border-gray;
                            margin-right: 15px;
                        }
                    }

                img {
                    width: 35px;
                    height: 35px;
                    overflow: hidden;
                    border-radius: 35px;
                    margin-right: 15px;
                }

                > h5 {
                    width: 33%;
                }
            }
        }
    }
}