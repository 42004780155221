@import 'styles/extends/_generics';

.tr__assign-members {
    .tr__dropdown {
        &--list {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid $border-gray;
        }
    }
}