@import 'styles/extends/_generics';

.tr__coupon-notification {
    .tr__dropdown {
        label {
            font-size: $medium-size - 2px;
            text-align: left;
        }
    }
    
}