@import 'styles/extends/_generics';
$panel-width: 90%;
.tr__edit-access-permission{
    .tr__modal--content{
        width: $panel-width;
        &--wrapper{
            height: 600px;
            overflow: auto;
            margin: 0 !important;
            padding: 0 20px !important;
        }
        &__footer{
            .tr__button {
                min-width: 10%;
                margin-left: 50px;
            };
        }
    }
    .tr__edit-access-permission__modal--form{
        .description {
            padding-top: 20px;
            margin-bottom: 10px;
        }
        #table {
            th {
                text-align: center;
                font-weight: bold;
                @extend %f-medium;
            }
        }
        .tr__tab {
            .table-header {
                .table-header-1 {
                    th {
                        position: sticky;
                        top: -2px;
                    }
                }
                @media screen and (max-width: 2050px){
                    .table-header-2 {
                        th {
                            position: sticky;
                            top: 40px;
                        }
                    }
                }
                @media screen and (min-width: 2051px){
                    .table-header-2 {
                        th {
                            position: sticky;
                            top: 37px;
                        }
                    }
                }

            }
        }
    }
}
.tr__organization-brand{
    padding-bottom: 10px;
    img {
        border-radius: 50%;
    }
}

#table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#table td, #table th {
    border: 1px solid #cbb;
    padding: 8px;
    text-align: center;
}

#table tr:nth-child(even){background-color: #f2f2f2;}

#table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #D8D8D8;
    color: #777777;
}
