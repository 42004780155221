@import 'styles/extends/_generics';

.tr__document-modal {
    .tr__document-modal--item {
        @extend %border-double-line;
        
        &:last-child {
            border-bottom: 0;
            
            &:after {
                display: none;
            }
        }

        > .tr__link {
            margin: $modal-padding;
        }

        .tr__document-modal--field {
            padding: $modal-padding;
            border-bottom: 1px solid $multiselect-gray;

            &:last-child() {
                border-bottom: 0;
            }
    
            .label {
                flex: 0 0 25%;
                color: $label-gray;
            }
    
            .value {
                flex-grow: 1;
            }
        }
    }
}