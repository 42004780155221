@import 'styles/extends/_generics';
.tr__vessel-schedule-form {
    .tr__radio-group {
        .tr__form-radio {
            min-width: 35%;
        }     
    }
    .tr__dropdown {
        &__label {
            .tr__field-input {
                margin-top: 3px;
                &.error {
                    border-bottom-color: $error-red !important;
                }
            }
        }
    }
    .form-btns {
        .tr__button {
            width: 100%;
        }
    }
}