@import 'styles/extends/_generics';

$input-width: 300px;

.tr__shipment-log {
    @extend %card-box;
    @include flex(column);

    &--title {
        @extend %border-double-line;
        padding-bottom: $modal-padding;

        .tr__field {
            min-width: $input-width;
            &--wrapper {
                > i {
                    color: $main-pink;
                }
            }
        }
    }

    &--content {
        @extend %border-double-line;
        flex-grow: 1;
        padding: $modal-padding 0;

        .tr__table {
            box-shadow: none;
            background-color: transparent;

            &--header {
                border-bottom: 1px solid $multiselect-gray;
                padding-bottom: 10px;

                .table-header {
                    .column {
                        width: 200px;
                        flex: inherit;

                        &.activity {
                            flex-grow: 1;
                        }
                    }
                }
            }
    
            &--row {
                align-items: initial !important;
                &__column {
                    cursor: default;
                    padding: 15px;
                    width: 200px;
                    min-width: auto;
                    color: $black;
                    border-right: 1px solid $multiselect-gray;
                    
                    &:nth-child(3) {
                        flex-grow: 1;
                    }
            
                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }
    }

    &--footer {
        padding-top: $modal-padding;
    }
}