@import 'styles/extends/_generics';

.tr__port-info {
    .tr__modal--content {
        width: 1300px;
    }
    .tr__table {
        &--row__column {
            min-width: 15%;
            &:first-child {
                padding-left: 20px;
            }
        }
        .table-header {
            .column {
                flex: 0 0 15%;
            }
        }
    }
}