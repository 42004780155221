@import 'styles/extends/_generics';
.tr__action-top-header {
    .action {
        h4 {
            background-color: $white;
            padding: 10px 15px;
            font-size: $small-size;
            border: 1px solid $bottom-border-gray;
            border-radius: 3px;
            margin-right: 200px;
            @extend %clickable;
    
            i {
                margin-right: 10px;
                color: $placeholder-gray;
            }
        }
        .group-option {
            padding-left: 30px;
            .created {
                background-color: $main-blue;
                padding: 10px 15px;
                color: $white;
                font-size: $small-size;
                border: 1px solid $bottom-border-gray;
                border-radius: 3px;
                @extend %clickable;
            }
            .received {
                background-color: $white;
                padding: 10px 15px;
                color: $placeholder-gray;
                font-size: $small-size;
                border: 1px solid $bottom-border-gray;
                border-radius: 3px;
                @extend %clickable;
            }
        }
    }
}