@import 'styles/extends/_generics';

$edge-radius: 5px;
$btn-min-width: 150px;
$panel-width: 500px;
$task-link-radius: 8px;

.tr__shipment-issue {
    @extend %card-box;
    padding: 0;

    &--header {
        @extend %border-double-line;
        padding: $modal-padding;

        >i {
            font-size: $h4;
            transform: rotateY(180deg);
            cursor: pointer;
        }
        .main-title {
            width: calc(100% - 500px);
            .issue-info {
                flex-grow: 1;
                width: 90%;
    
                p {
                    width: 90%;
                    font-size: $tiny-size;
                    color: $label-gray;
                }
            }
        }

       

        .issue-btns {
            .tr__link {
                color: $grey;
            }

            .tr__button {
                padding: 10px 15px;
            }
        }

        .closed-issue,
        .open-issue {
            width: $panel-width;
            justify-content: space-between;
            padding: 10px;
            border-radius: 5px;

            &--info {
                width: 90%;
                justify-content: space-between;

                > h6 {
                    width: 15%;
                    @extend %ellipse-text;
                    color: $black;
                }

                >img {
                    width: 40px;
                    height: 40px;
                    border-radius: 40px;
                    overflow: hidden;
                }

                p {
                    &.label {
                        color: $label-gray;
                        margin: 0 5px;
                    }
                }
            }
        }

        .closed-issue {
            background-color:$collapse-blue;
            > i {
                width: $closed-issue-icon-size;
                height: $closed-issue-icon-size;
                border-radius: $closed-issue-icon-size;
                background-color: $light-blue;
                color: $white;
                @include flex(row, center, center);
            }
        }

        .open-issue {
            > i {
                width: $closed-issue-icon-size;
                height: $closed-issue-icon-size;
                border-radius: $closed-issue-icon-size;
                background-color: transparent;
                color: $main-pink;
                @include flex(row, center, center);
            }
            
            background-color: rgba($main-pink, 0.05)
            
        }


    }

    &--content {
        padding: $modal-padding;

        .comments {
            margin-right: 10px;
            flex-grow: 1;
            width: calc(100% - 500px);

            &-item {
                .comments-item--avatar {
                    img {
                        overflow: hidden;
                        display: inline-block;
                    }
                }

                &:nth-last-child(1),
                &:nth-last-child(2) {
                    border-bottom: none;

                    .comments-item--avatar {
                        width: $issue-avatar;
                        height: $issue-avatar;
                        border-radius: 40px;

                        &:after {
                            display: none;
                        }
                    }
                }

                &:nth-last-child(1) {
                    .comments-item--content {
                        margin-bottom: 0;
                    }
                }

                &--content {
                    flex-grow: 1;

                    >div {
                        flex-grow: 1;
                    }

                    >form {
                        width: 100%;

                        >div {
                            div {
                                flex-grow: 1;
                            }
                        }
                    }

                    .user {
                        .h6 {
                            color: $black;
                        }

                        p {
                            font-size: $tiny-size;
                            color: $placeholder-gray;
                        }
                    }

                    .content {
                        line-height: 1.5;

                        p {
                            margin-bottom: 10px;
                        }
                    }

                    .gallery {
                        @include flex(row, center);

                        img {
                            max-height: 100px;
                            max-width: 100px;
                        }
                    }
                }

                &.form {
                    padding: $modal-padding;
                    border: 2px solid $bottom-border-gray;
                    border-radius: $edge-radius;

                    .tr__button {
                        padding: 10px 25px;
                    }

                    .tr__field {
                        border-bottom: 1px solid $bottom-border-gray;

                        textarea {
                            width: 97%;
                            border-bottom: none;
                        }
                    }
                }
            }

            .tr__comment {
                border: 1px solid $bottom-border-gray;
            }
        }

        .members {
            flex: 0 0 $panel-width;
            border-radius: $edge-radius;
            max-width: $panel-width;

            div.tr__task--link {
                background-color: rgba($pink, 0.05);
                padding: 14px 18px;
                border-radius: $task-link-radius;
                color: $label-gray;
                font-size: $tiny-size;
                @extend %f-medium;

                >i {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: $pink;
                    margin-right: 10px;
                    font-size: $tiny-size;
                    color: $white;
                    @include flex(row, center, center, nowrap);
                }

                >span {
                    color: $label-gray;
                    margin-right: 10px;

                    &:last-child {
                        color: $base-text-color;
                        margin-left: 5px;
                        font-size: $base-size;
                        width: 70%;
                        display: inline-block;
                        @extend %ellipse-text;
                    }
                }

                &.done {
                    filter: grayscale(100%);
                    margin-bottom: 20px;
                }
            }

            .tr__member {
                .h5 {
                    font-size: $h6 !important;
                }
            }

            &--created {
                border-bottom: 1px solid $bottom-border-gray;
                padding: $modal-padding;
                background-color: $light-gray;

                .tr__member {
                    &--roles {
                        text-align: right;

                        p {
                            @extend %f-regular;
                        }
                    }
                }

                >.tr__member {
                    border-bottom: 0;
                    padding: 5px 0;

                    >img {
                        width: 40px;
                        height: 40px;
                        border-radius: 40px;
                        overflow: hidden;
                    }
                }
            }

            &--assignees {
                padding: $modal-padding;
                background-color: $light-gray;

                .list {
                    .tr__member {
                        .tr__tooltip {
                            width: 25%;

                            h5 {
                                width: 100%;
                                margin-right: 0;
                            }
                        }

                        .tr__button {
                            margin-right: 10px;
                        }

                        .h5 {
                            @extend %f-regular;
                            width: 15%;
                        }

                        img {
                            width: $organization-avatar-size;
                            height: $organization-avatar-size;
                            border-radius: 40px;
                            overflow: hidden;
                            margin-right: 10px;
                        }

                        >i {
                            @include flex(row, center, center);
                            width: $organization-avatar-size;
                            height: $organization-avatar-size;
                            border-radius: 40px;
                            overflow: hidden;
                            margin-right: 10px;
                            border: 2px solid $white;
                            background-color: $bottom-border-gray;

                        }

                        &--roles {
                            flex-grow: 1;
                            @include flex(row, center, flex-start);

                            i {
                                margin-right: 15px;
                                font-size: $medium-size;
                                color: $grey;
                            }

                            .role-icons {
                                width: 100%;
                                justify-content: flex-end;
                            }
                        }

                        .tr__tooltip {
                            &--popup {
                                min-width: auto;

                                .tags {
                                    i {
                                        color: $white;
                                        margin: 5px 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &--add-assignees {
                .tr__dropdown__label {
                    &.active {
                        .tr__selected-values {
                            >div:first-child {
                                position: absolute;
                            }

                            .tr__field.hidden {
                                visibility: hidden;
                            }

                            &--item {
                                max-width: $btn-min-width;

                                >h6 {
                                    @extend %ellipse-text;
                                    width: 75%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--footer {
        @extend %section-footer;

        .tr__button {
            min-width: $btn-min-width;
        }
    }

    .closed-issue {
        margin: -5px 0;
    }
}


// .closed-issue, .open-issue {
//     padding: 10px;
//     border-radius: 5px;

//     > i {
//         width: $closed-issue-icon-size;
//         height: $closed-issue-icon-size;
//         border-radius: $closed-issue-icon-size;
//         background-color: $light-blue;
//         color: $white;
//         @include flex(row, center, center);
//     }

//     &--info {
//         img {
//             width: $issue-avatar;
//             height: $issue-avatar;
//             border-radius: $issue-avatar;
//             overflow: hidden;
//         }

//         .h6 {
//             color: $black;
//         }

//         p {
//             &.label {
//                 color: $label-gray;
//                 margin: 0 5px;
//             }
//         }
//     }
// }
