@import 'styles/extends/_generics';

.tr__input-tags {
    position: relative;

    > label {
        font-size: $tiny-size;
        display: flex;
        color: $label-gray;
        @extend %f-medium;
    }

    .tr__tooltip {
        width: 14px;  
        li{
            font-size: $small-size;
            font-weight: normal;
        }
    }

    &__input {
        flex-grow: 1;

        >input{
            @extend %standard-input;
            border-bottom: none;
        }
    }

    .label {
        padding: 10px 0px;
        line-height: 1.5;
        word-break: break-all;
    }

    &--wrapper {
        position: relative;
        display: inline-flex;
        flex-wrap: wrap;
        width: 100%;
        border-bottom: 1px solid #D8D8D8;
    }

    &--values{
        @include flex(true, center);
        background-color: $white;
        border: 1px solid $bottom-border-gray;
        border-radius: 3px;
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 5px;

        > i{
            font-size: $h2;
            margin-right: 10px;
            color: $label-gray;

            &:last-child {
                margin-right: 0;
                margin-left: 10px;
                @extend %close-icon;
                font-size: 12px;
                width: 15px;
                height: 15px;
            }
        }

        i, img {
            width: $icon-size;
            margin-right: 10px;
            height: $icon-size;
            border-radius: $icon-size;
            @include flex(row, center, center);
        }

        .info {
            flex-grow: 1;
        }

        &:last-child {
            margin-right: 0;
        }

        .h6 {
            font-size: 12px;
            min-width: 34px;
        }

        p {
            font-size: $tiny-size;
            color: $placeholder-gray;
        }
    }
}