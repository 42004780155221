@import "styles/extends/_generics";
@import "styles/helpers/_variables";

$edge-radius: 5px;

$border-radius: 5px;
.tr_reference {
    width: 100%;

    &--header {
        background-color: $button-hover-gray;
        padding: 20px;
        border-radius: $border-radius;
        &.submitted {
            background-color: rgba($bonus-blue, $alpha: 0.1);
        }
        &.rejected {
            background-color: rgba($base-text-color, $alpha: 0.1);
        }
        &.confirmed {
            background-color: rgba($bonus-green, $alpha: 0.1);
        }
        &.cancelled {
            background-color: rgba($placeholder-gray, $alpha: 0.1);
            background-color: $placeholder-gray;
        }
        &.draft {
            background-color: rgba($bonus-orange, $alpha: 0.1);
        }
        &.mark_as_active_shipment {
            background-color: rgba($main-green, $alpha: 0.1);
        }
    }

    .tr__vessel--search__info {
        .right__port {
            .block-column {
                padding: 40px;
            }
        }
        .border-column {
            border: 1px solid $bottom-border-gray;
            .port__brand {
                padding: 20px;
            }
        }
        .port__info {
            padding: 30px 0;
            border-left: 1px solid $bottom-border-gray;
            border-right: 1px solid $bottom-border-gray;
        }
    }
    .tr__vessel--search__footer {
        .content__view__header__column {
            &:first-child {
                width: 30%;
            }
            &:nth-child(2) {
                width: 20%;
            }
        }
        .content__view__row {
            margin-bottom: 10px;
            // div {
            .tr__info-cell {
                flex: 0 0 30% !important;
                margin-bottom: 10px;
            }
            .title {
                padding-left: 30px;
                border-bottom: 1px solid $multiselect-gray;
                .tr__info-cell--title {
                    color: $placeholder-gray;
                    padding-top: 10px;
                }
                .tr__info-cell--label {
                    color: $base-text-color;
                }
                .date {
                    color: $label-gray;
                }
            }
            // }
        }
    }

    &--form {
        width: $content-width;
        margin: 0 auto;
        margin-top: 40px;

        h2 {
            @extend %border-double-line;
            padding-bottom: $modal-padding;
        }

        h3 {
            margin-top: 20px;
            padding-bottom: $modal-padding;

            span {
                color: $main-blue;
            }
        }

        &__details {
            margin-bottom: 60px;

            .reference--info {
                @include flex(row, center);
                padding: 15px 0;
                border-bottom: 1px dashed $border-gray;
                margin: 0;
                user-select: none;

                > label {
                    min-width: 45%;
                    font-size: $base-size;
                    @extend %f-medium;
                    color: $label-gray;
                }

                span {
                    word-break: break-all;
                    p {
                        max-width: 30%;
                    }
                }
            }

            .full-size {
                label {
                    min-width: 22%;
                }

                span {
                    overflow: inherit;
                    white-space: normal;
                }
            }

            .half-size {
                label {
                    min-width: 50%;
                }
            }
        }

        .booking-number {
            font-size: $h6;
            border-bottom: 1px dashed $bottom-border-gray;
            padding: 30px 0 16px 0;
            p {
                color: $label-gray;
                margin-right: 65px;
            }

            > span {
                max-width: calc(100% - 175px);
                word-break: break-all;
            }
        }

        .reference-tab {
            border: 2px solid $bottom-border-gray;
            @extend %tab-border-bottom;
            border-radius: $edge-radius;
            margin-top: 60px;
        }
    }
}
