@import 'styles/extends/_generics';

$box-radius: 5px;
.tr__issues-header {
    padding: $content-padding !important;
    background-color: $button-hover-gray;
    border-radius: $box-radius;
    &--action {
        .right {
            float: right;
        }
        .col-2 {
            width: unset;
        }
    }
}