@import 'styles/extends/_generics';

.tr__booking-task-header {
    .column {
        padding: $content-padding;
        width: 11%;
        flex: inherit;

        &.number {
            width: 15%;
        }
        
        &.booking-no {
            width: 15%;
        }

        &.date-receipt {
            width: 15%;
        }

        &.port-loading,
        &.port-discharge {
            width: 13%;
        }

        &.status {
            width: 7%;
        }

        p {
            color: $label-gray;
        }

        .icon-filter {
            cursor: pointer;
        }
    }
}