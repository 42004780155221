@import 'styles/extends/_generics';

$search-form-width: 398px;

.tr__search-forms {
    border-radius: 5px;
    background-color: $white;
    box-shadow: $box-shadow;
    width: $search-form-width;
    .box-header {
        .tr__shipment-options {
            width: 100px;
            border: 1px solid white;
            border-radius: 5px;
            padding: 5px;
            .tr__dropdown__label.select {
                border-bottom: none;
                padding: unset;
            }
            .tr__dropdown__items {
                z-index: 5 !important;
                color: $black;
                min-width: 100px !important;
                .tr__dropdown__item {
                    padding: 10px 5px !important;
                }
            }
        }
    }

    .tr__tabs--content {
        margin: 0;
        .tr__container-form {
            .form-btns {
                .tr__button {
                    width: 100%;
                }
            }

        }  
    }

    .tr__tabs--nav {
        ul {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $border-gray;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 1px;
                width: 100%;
                border-bottom: 1px solid $border-gray;
            }

            li {
                border: none;
                font-size: $h6;
                border-bottom: 2px solid transparent;
                background-color: transparent;
                font-size: $small-size;
                
                &.active {
                    color: $main-blue;
                    background-color: $white;
                    border-bottom-color: $main-blue;
                    @extend %f-medium;
                }
            }
        }
    }
}
