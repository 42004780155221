@import "styles/extends/_generics";

.tr__bk--collaborators {
    .tr__member {
        > img {
            border-radius: 20px;
            overflow: hidden;
        }
    } 
    
    .tr__assignee-list .list {
        overflow: auto;
    }
    
    .tr__modal.has-title .tr__modal--content--wrapper {
        max-height: 550px;
    }
}
