@import 'styles/extends/_generics';

.tr__shipments-map {
    position: relative;
    .tr__search-forms {
        position: absolute;
        z-index: 11;
        top: 30px;
        left: 30px;
    }

    .tr__shipment-type {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
