@import 'styles/extends/_generics';

.tr__all-collaborators {
  .tr__modal--content--wrapper {
    .tr__assignee-list {
      .tr__member  {
         > h5 {
          width: 35%;
        }
        .tr__tooltip {
          width: 35%;
          h5 {
            width: 100%;
          }
        }
        > i {
          &.icon-users {
            width: 35px;
            height: 35px;
            border-radius: 35px;
            @include flex(row, center, center);
            background-color: $bottom-border-gray;
            margin-right: 15px;
          }
        }

        img {
          width: 35px;
          height: 35px;
          overflow: hidden;
          border-radius: 35px;
          margin-right: 15px;
        }

        &--roles {
          justify-content: flex-end;
        }
      }

      &:last-child {
        .tr__member  {
          > h5 {
            width: 20%;
            margin-right: 0;
          }
          .tr__tooltip {
            width: 20%;
            h5 {
              width: 100%;
              margin-right: 0;
            }
          }
          
        }
      }
    }
  }
}