@import 'styles/extends/_generics';

$panel-width: 320px;
$remove-icon-size: 20px;

.tr__shipment-filter--group {
   &-remove-icon {
       position: absolute;
       cursor: pointer;
       right: 0;
       top: 0;
       transform: translate(50%, -50%);
       width: $remove-icon-size;
       height: $remove-icon-size;
       border-radius: $remove-icon-size;
       background-color: $error-red;
       color: white;
       @include flex(row, flex-end, center);
       font-size: 22px;
       line-height:  1;
   }
}
