@import 'styles/extends/_generics';

.tr__info-block {
    &--content {
        // border: 1px solid $border-gray;
        border-radius: 5px;
        min-height: 250px;
        box-shadow: 2px 7px 18px #9f9f9f;
        overflow-x: hidden;
        background: $white;
        &.high-column {
            max-height: 700px;
            overflow-x: hidden;
        }
        > .row {
            > .col-12 {
                .tr__info-row {
                   padding: 10px 20px;
                }
                &:last-child {
                    .tr__info-row {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}