@import 'styles/extends/_generics';
@import 'styles/helpers/_variables';

.tr__add-request-details {
    .tr__add-request-form {
        width: $content-width;
        margin: 0 auto;
        margin-top: 20px;
        &__details {
            padding-bottom: 30px;
            border-bottom: 1px dashed $bottom-border-gray;
            .tr__radio-group {
                margin: 0 -12px;
               
                .tr__form-radio {
                    width: 25%;
                    padding-right: 12px;
                    padding-left: 12px;
                    margin: 0;
                }
            }
            .tr__radio-group--wrapper {
                label {
                    font-size: $h2;
                }
            }
            .tr__checkbox-group {
                .tr__form-checkbox {
                    min-width: 30%;
                }
            }
        }
        .add-container {
            color: $main-pink;
            font-size: $small-size;
        }
    }

    &.hidden {
        display: none;
    }
}
    