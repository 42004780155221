@import 'styles/extends/_generics';

.tr__days-input {
    &--header {
        position: relative;

        .text-label {
            @extend %f-medium;
            font-size: $tiny-size;
            display: block;
            color: $label-gray;
        }

        .tr__form-checkbox {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    &--field {
        border-bottom: 1px solid $border-gray;
        border-radius: 3px;
        background-color: $white;

        &.disabled {
            pointer-events: none;
            @include opacity(0.4);
            user-select: none;
        }

        .tr__field,
        .tr__dropdown {
            input, .tr__dropdown__label {
                border-radius: 0;
                border: none;
                &.select {
                    text-align: right;
                }
            }
        }

        .tr__field {
            flex-grow: 1;
        }

        .tr__dropdown {
            width: 50px;

            &__items {
                min-width: 80px;
            }
        }
        .tr__field.number  {
            .number-btns {
                border: none;
                .tr__button {
                    width: 22px;
                    height: 22px;
                    border-radius: 3px;
                    border: 1px solid $border-gray;
                    margin-right: 3px;
                    padding: 4px 1px;
                    i {
                        font-size: 10px;
                        color: $grey;
                    }
                    &:hover {
                        border: 1px solid $main-blue;
                        background-color: $white;
                        i {
                            color: $main-blue;
                        }
                    }
                }  
            }
        } 
    }
}