@import 'styles/extends/_generics';

.tr__details-box {
    margin-bottom: 40px;
}


@media (min-width: 576px){
    .col-section{
        width: 12.5%;
    }
}