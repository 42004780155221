
@import 'styles/extends/_generics';

$modal-width : 1405px;

.tr__edit-extracted-info {
    .tr__modal--content {
        width: $modal-width;
    }
    .tax-image {
        width: 48%;
        overflow: hidden;
        overflow-x: visible;
        embed {
            max-width: 100%;
        }
    }
    .tax-info {
        width: 48%;
    }
    .tr__add-link-task__footer{
        @extend %section-footer;

        .tr__link {
            margin-right: 40px;
            color: $label-gray;
        }
    }
}