@import 'styles/extends/_generics';

$box-radius: 5px;

.tr__task-management--buttons {
    padding: $content-padding;
    background-color: $button-hover-gray;
    border-radius: $box-radius;
    &.display-none {
        margin-bottom: 50px;
    }

    .left, .right {
        .tr__button {
            margin-left: 20px;

            &:first-child {
                margin: 0;
            }
        }
    }
    
}