@import 'styles/extends/_generics';
$height-modal: 800px;

.tr__add-organization {

    .tr__modal--wrapper {
        .tr__modal--content {
            max-height: $height-modal;
            overflow: hidden;
            form {
                max-height: $height-modal - 100px;
                overflow: hidden;
                overflow-y: visible;
            }
        }
    }
    .details-heading {
        margin: 0 0 20px 0;
        padding: 0;
        border-bottom: 0;

        &:after {
            display: none;
        }
    }

    .tr__organization-details-form,
    .tr__billing-details-form {
        padding: $modal-padding;
        margin: 0;

        .tr__field {
            &.last {
                margin: 0;
            }
        }

        &__card {
            .react-datepicker-popper {
                right: 0px !important;
                top: -70px !important;
                left: auto !important;
            }
        }
    }

    .tr__organization-details-form {
        border-bottom: 1px dashed $border-gray;  
    }

    .tr__organization-details-form__info {
        margin: 0;
    }
}