@import 'styles/extends/_generics';

.tr__extracted-info-table {
    margin-bottom: 30px;
    .remove-card {
        position: absolute;
        right: $remove-icon-size / 2;
        width: $remove-icon-size;
        height: $remove-icon-size;   
        border-radius: $remove-icon-size; 
        background-color: $error-red;
        color: $white;
        @include flex(row, flex-end, center);
        font-size: $h1;
        line-height: 1;
    }
    .tr__extracted-info-list {
        border: 1px solid $bottom-border-gray;
        border-radius: 5px;
        &--header {
            padding: 11px 20px;
            background-color: $button-hover-gray;
            .sort-icon {
                i {
                    color: $label-gray;
                    background-color: $bottom-border-gray;
                    padding: 5px;
                    border-radius: 5px;
                    margin-left: 7px;
                    cursor: pointer;
                }
            }
            .icon-pencil:hover{
                cursor: pointer;
            }
            .tr__field-input {
                background-color: #ededef;
                width: 200px;
            }
        }
        &--table {
            .table-content {
                border-bottom: 1px solid $bottom-border-gray;
                .column {
                    padding: 15px;
                    .icon-times {
                        @extend %close-icon;
                    }
                    &.name {
                        border-right: 1px solid $bottom-border-gray ;
                    }
                    .full{
                        flex-grow: 1;
                    }
                }
                &:last-child {
                    border: none;
                }
            }
            .add-new-line {
                color: $main-pink;
                padding: 16px 20px;
                cursor: pointer;
            }
        }
    }
}