@import 'styles/extends/_generics';

.tr__shipment-documents {
    @extend %card-box;

    &--table {
        flex-grow: 1;
        width: 70%;
        

        
    }

    &--list {
        position: relative;
        max-height: 500px;
        overflow: hidden;
        overflow-y: auto;

        .tr__document-header {
            position: sticky;
            top: 0;
            z-index: 9999;
        }
    }

    &--uploader {
        width: 25%;
        margin-left: 30px;
    }

    .tr__table {
        box-shadow: none;
        background-color: transparent;
        overflow: visible;

        &--row {
            align-items: initial !important;
            
            &:nth-child(2) {
                border: 1px solid $bottom-border-gray;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                margin-top: 15px;
            }

            &:nth-child(3) {
                border: 1px solid $bottom-border-gray;
                border-top: none;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                margin-bottom: 15px;
            }
            &:nth-child(4) {
                border-top: 1px solid $multiselect-gray;
            }

            &__column {
                padding: 15px;
                flex: 0 0 20%;
                border-right: 1px solid $multiselect-gray;

                &:first-child {
                    flex: 0 0 25%;
                    padding-left: 0;
                }
        
                &:last-child {
                    border-right: 0;
                    .tr__button {
                        padding: 10px 15px;
                        margin: 0 auto;
                    }
                }

                &:nth-child(2) {
                    flex: 0 0 15%;
                    i {
                        font-size: $medium-size;
                    }
                }

                .tr__collaborators {
                    > span,
                    .tr__organization-members-avatar {
                        flex-grow: 1;
                        order: 2;
                        @include flex(row, center, flex-end);
                    }
                }

                .tr__doc-title {
                    padding-right: 20px;
                    width: 100%;
                    i {
                        color: $main-blue;
                    }
                    .favorite-icon { 
                        margin-left: 0;
                        width: 100%;
                    }
                    .view-document {
                        margin-left: 30px;
                        width: 100%;
                    }
                    h4 {
                        max-width: 75%;
                    }
                }
                .tr__doc-date {
                    p {
                        &:first-child {
                            display: inline-block;
                            line-break: anywhere;
                        }
                    }
                }
            }
        }
    }

    &--uploader {
        .tr__dropdown__label {
            span.selected {
                display: inline-block;
                padding-right: 20px;
                @extend %ellipse-text;
            }
        }
    }

    .tr__collaborators {
        width: 100%;
        .tr__link {
            width: 100%;
            justify-content: space-between;
            margin: 0;
            span {
                min-width: 70px;
            }
        }

        .tr__organization-members-avatar {
            p {
                display: none;
            }
        }
    }

    .tr__doc {
        &-title {
            &:first-child {
                padding-left: 15px;
            }
        }
        &-date {
            .date {
                font-size: $tiny-size;
                color: $label-gray;
            }
        }
    }
}
