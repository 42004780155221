@import 'styles/extends/_generics';

.tr__search-result {
    @extend %search-form-box;
    overflow: hidden;
    height: 80vh;

    .form-group {
        padding: 20px 10px;
        max-height: calc(100% - 77px);
        overflow: hidden;
        overflow-y: visible;
    }

    &--header {
        padding: 0 10px;
        > i {
            @extend %close-icon;
        }
    }

    .form-btns {
        .tr__button {
            width: 100%;
        }
    }

    .tr__collapse {
        .tr__collapse--content {
            padding: 0;
        }
    }

    &--process {
        
        .step {
            &--icon {
                position: relative;
                i {
                    @include flex(row, center, center);
                    width: $icon-size;
                    height: $icon-size;
                    border-radius: $icon-size;
                    background-color: $bottom-border-gray;
                    color: $label-gray;
                    font-size: $tiny-size;
                    position: relative;
                    z-index: 1;
                }

                &:after {
                    content: '';
                    @include centered-position(true, false);
                    top: 0;
                    height: 100%;
                    width: 1px;
                    border-left: 1px dashed $bottom-border-gray;
                }
                
            }

            &--info {
                flex-grow: 1;
                padding: 0 10px;

                > p {
                    font-size: $small-size;
                    margin-top: -5px;
                }

                
                .disabled {
                    color: $placeholder-gray;
                }
                
                &__times {
                    div {
                        padding: 10px 0;
                        border-bottom: 1px solid $bottom-border-gray;

                        &:first-child {
                            padding-top: 0;
                        }

                        &:last-child {
                            border-bottom: 0;
                            padding-bottom: 0;
                        }

                        .h6 {
                            &:first-child {
                                color: $placeholder-gray;
                            }
                        }
                    }
                }
            }

            &:last-child {
                .step--icon {
                    &:after {
                        display: none;
                    }
                }
            }
        
            &.done, &.in-progress {
                .step--icon {
                    i {
                        background-color: $main-blue;
                        color: $white;
                    }
            
                    &:after {
                        border-left-color: $main-blue;
                    }
                }
            }

            &.done, &.in-progress {
                .step--info {
                    &__times {
                        div {
                            .h5 {
                                &:last-child {
                                    color: $black;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--containers {
        background-color: $light-pink;
        padding: 10px;
        z-index: 2;
        position: relative;
    }
}

.tr__search-params {
    .param-row {
        padding: 15px;
        border-bottom: 1px solid $bottom-border-gray;

        &:last-child {
            border-bottom: 0;
        }
    }

    &--item {
        width: 50%;

        &.block {
            width: 100%;
        }

        p {
            font-size: $small-size;
            color: $placeholder-gray;
        }

        .h5 {
            font-size: $small-size;
        }
    }
}
