@import "styles/extends/_generics";

$input-width: 300px;

.tr__document-signature {
    @extend %card-box;
    @include flex(column);

    &--title {
        @extend %border-double-line;
        padding-bottom: $modal-padding;

        .tr__field {
            min-width: $input-width;
            &--wrapper {
                > i {
                    color: $main-pink;
                }
            }
        }
    }

    &--btn-add-doc {
        width: 200px;
    }
    &--icon-name {
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        border-radius: 100%;
        justify-content: center;
        background-color: #99adc0;
        margin-right: 5px;
        &::before {
            color: $white;
        }
    }

    &--select-generate {
        border: 1px solid $main-blue;
        border-radius: 5px;
        padding: 5px;
        .tr__dropdown__label.select {
            border-bottom: none;
            color: $main-blue;
            font-size: 12px;
            margin-left: 7px;
            margin-right: 5px;
        }
        .tr__dropdown__label .placeholder {
            color: #69a6ff;
        }
        .tr__dropdown__label i {
            font-size: 10px;
            color: #69a6ff;
        }
        .tr__dropdown__items {
            z-index: 5 !important;
            color: $main-blue;
            .tr__dropdown__item {
                padding-top: 10px;
            }
        }
    }

    .icon-times:before {
        color: $main-pink;
    }

    &--btn-download {
        font-size: 11px;
    }

    &--content {
        @extend %border-double-line;
        flex-grow: 1;
        padding: $modal-padding 0;
        .tr__table {
            box-shadow: none;
            overflow: unset;
            &--header {
                border-bottom: 1px solid $multiselect-gray;
                padding-bottom: 10px;
                .table-header {
                    .column {
                        width: 100%;
                        flex: inherit;
                        &.activity {
                            flex-grow: 1;
                        }
                    }
                }
            }

            &--row {
                align-items: initial !important;
                &__column {
                    display: flex;
                    flex-flow: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15px;
                    width: 100%;
                    min-width: auto;
                    color: $black;
                    border-right: 1px solid $multiselect-gray;
                    &:hover {
                        opacity: 1 !important;
                    }
                    &:nth-child(3) {
                        flex-grow: 1;
                    }

                    &:last-child {
                        border-right: 0;
                    }
                }
                .etherum {
                    color: $main-blue;
                    background-color: $pending-status-blue;
                    padding: 5px;
                }
                .issuing {
                    color: $pending-status-yellow;
                    background-color: $light-yellow-status;
                    padding: 5px;
                }
                .issued {
                    color: $bonus-green;
                    background-color: $pending-status-green;
                    padding: 5px;
                }
            }
        }
    }

    &--footer {
        padding-top: $modal-padding;
    }
}
