@import 'styles/extends/_generics';

.tr__verify-details-form--group {
    // margin-bottom: 40px;
	&--content {
		&.address-frame {
			height: 358px;
		}
		
	}
    .with-label {
        @include flex(row, flex-end, space-between);

        > .tr__field {
            flex-grow: 1;
        }

        > p {
            margin: 0 0 10px 10px;
        }
	}

	.tr__description-input {
		.tr__tooltip {
			i {
				color: $label-gray !important;
			}
		}
	}
	.tr__collapse.details {
		overflow: visible !important;
	}

	.mapboxgl-ctrl {
		position: relative;
		z-index: 1;

		&-geocoder--icon,
		&-geocoder--pin-right,
		&-geocoder--input {
			display: none;
		}

		.suggestions {
			position: absolute;
			background-color: $white;
			border-radius: $border-radius;
			width: 100%;
			box-shadow: $component-shadow;
			margin-top: 5px;
			@extend %enable-transition;
			min-width: 200px;
			z-index: 1;
			max-height: 200px;
			overflow: hidden;
			overflow-y: visible;

			li {
				border: none;
				width: 100%;
				border-bottom: 1px solid $bottom-border-gray;
				padding: 15px 20px;
				cursor: pointer;
				@extend %enable-transition;

				&:hover {
					background-color: $light-gray;
				}

				&:last-child {
					border-bottom: 0;
				}

				.mapboxgl-ctrl-geocoder--suggestion {
					* {
						display: inline;
					}

					&-title {
						&:after {
							content: ',';
						}
					}
				}
			}
		}
	}

	.no-result {
		display: none;
	}

	.single-row {
		> label {
			border-top: 1px dashed $bottom-border-gray;
			font-size: $medium-size;
			display: block;
		}
	}
}