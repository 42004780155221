@import 'styles/extends/_generics';

$button-size: 200px;
.tr__action-button {
    padding: 20px;
    background-color: $button-hover-gray ;
    box-shadow: $component-shadow;
    .tr__button {
        width: $button-size;
        // &.cancel {
        //     background-color: $bg-grey;
        //     color: $grey;
        // }
    }
}