@import 'styles/extends/_generics';

.tr__edit-extracted-info {
    .tr__extracted-info-table {
        .table-content {
            .column {
                line-break: anywhere;
            }
        }
    }
    .message {
        color: $main-pink;
    }
}