%f-regular {
    font-family: 'HelveticaNeue' !important;
    font-weight: normal !important;
    font-style: normal !important;
}

%f-medium {
    font-family: 'HelveticaNeue' !important;
    font-weight: 500 !important;
    font-style: normal !important;
}