@import 'styles/extends/_generics';

$container-width: 800px;

.tr__edit-shipment-info {
    @extend %card-box;
    padding: 0;

    &--title {
        @extend %border-double-line;
        padding: $modal-padding;
    }

    &--content {
        // width: $container-width;
        // margin: 30px auto;
        padding: 20px;
        .add-vessel, .add-container {
            color: $main-pink;
            font-size: $small-size;
            cursor: pointer;
        }
        .tr__collapse {
            &--content {
                p:not(.error) {
                    border-bottom: 1px solid $bottom-border-gray;
                    line-height: normal;
                    min-height: 37px;
                }
    
                .tr__verify-details-form--group--content {
                    border-bottom: 1px dashed $bottom-border-gray;
                    padding-bottom: 30px;
                }
            }
            &.details {
                .tr__collapse--content {
                    padding: 0;
                }
            }
        }
        .tr__verify-details-form--group--content {
            border-radius: 5px;
            min-height: 275px;
            box-shadow: 2px 7px 18px #9f9f9f;
            background: $white;
            padding-bottom: 20px;
            padding-top: 5px;
        }  
    }

    &--footer {
        @extend %section-footer;
    }
}
// .tr__info-cell--content {
//     @extend %ellipse-text;
// }

