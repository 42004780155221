@import 'styles/extends/_generics';

.tr__active-shipments-header {
    h1 {
        color: $base-text-color;
    }

    p {
        color: $label-gray;
        margin-top: 5px;
    }

    h4 {
        padding: 10px 15px;
        font-size: $small-size;
        border: 1px solid $bottom-border-gray;
        border-radius: 3px;
        margin-right: 220px;
        @extend %clickable;
    }
    .btn_create_shipment {
        width: 166px;
    }

    .reload {
        width: 37px;
        height: 37px;
        border: 1px solid #ccc !important;
        margin-right: 10px;
    }

    .activeReload {
        background-color: #69A6FF;
        i {
            margin-right: 15px;
            color: white;
        }
        span {
            color: white;
        }
    }
    .deActiveReload {
        background-color: $white;
        i {
            margin-right: 15px;
            color: $placeholder-gray;
        }
    }
}

