@import 'styles/extends/_generics';

$size-avatar: 80px;
.tr__view-request-organization {
  .tr__view-requester {
    padding: 20px;
    background-color: rgba($light-blue, 0.2);
    border-radius: 5px;
    // @extend %border-double-line;
    &--avatar {
      width: $size-avatar;
      height: $size-avatar;
      overflow: hidden;
      border-radius:50%;

      img {
        display: inline-block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--info {
      padding-left: 20px;
      width: 85%;
      h4 {
        width: 100%;
        line-break: anywhere;
        font-size: $medium-size;
      }

      h5 {
        font-size: $small-size;
        color: $label-gray;
      }

      p {
        display: inline-block;
        line-break: anywhere;
      }
    }
  }

  h6 {
    font-size: $medium-size
  }

  .tr__dropdown {
    &__items {
      width: 100%;
      h6 {
        font-size: $base-size;
      }
    }
  }

  .row {
	.tr__dropdown {
		width: 50%;
		 &__items {
			width: 100%;
		}
	}
  }
}