@import 'styles/extends/_generics';
$width-selected-item: 150px;

.tr__add-collaborator {
    @extend %card-box;
    padding: 0;

    .tr__multiselect {
        padding-bottom: $modal-padding;
        border-bottom: 1px dashed $bottom-border-gray;

        &--wrapper {
            width: 90%;
            margin: 0 auto;
            .tr__dropdown__label {
                .tr__selected-values--item {
                    width: $width-selected-item;
                    h6 {
                        width: 75%;
                        @extend %ellipse-text;
                    }
                }
            }
            .tr__dropdown__items {
                .tr__selected-values--item {
                    h6 {
                        line-break: anywhere;
                    }
                }
            }
        }
    }

    &--title {
        @extend %border-double-line;
        padding: $modal-padding;
    }

    &--footer {
        @extend %section-footer;
    }
}