@import 'styles/extends/_generics';

.tr__shipment-document{
    @extend %file-upload-list;
    
    > li{
        padding: 20px;
        border-bottom: 1px dashed $bottom-border-gray;

        &:first-child {
            padding-top: 20px;
        }

        &:last-child{
            border-bottom: none;
        }
        span{
            color: $black;
        }
    }
}