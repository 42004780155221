@import 'styles/extends/_generics';

$panel-width: 320px;

.tr__request-filter {
    z-index: 9;

    .tr__modal--content {
        width: $panel-width;
        margin: 0 0 auto auto;
        transform: none;
        border-radius: 0 !important;
        min-height: calc(100% - #{$header-height});
        margin-top: $header-height;
        
        &__title {
            border-radius: 0;
        }

        .tr__modal--close {
            display: none;
        }
    }

    &.hidden {
        .tr__modal--content {
            transform: translate($panel-width, 0);
        }
    }

    .tr__dropdown__label {
        &.select {
            font-size: $small-size;
        }
    } 
    
    .tr__field-input {
        font-size: $small-size;
    }

    .tr__datepicker > label {
        font-size: $tiny-size;
    }

    &--group {
        @extend %border-double-line;

        &.last {
            border: none;

            &:after {
                display: none;
            }
        }

        .route-icon {
            margin-top: -8px;
            height: 52%;
        }

    }

    &--btns {
        .tr__button {
            flex: 0 0 calc(50% - 5px);
            margin-right: 10px;

            &:last-child {
                margin: 0;
            }
        }
    }

    .react-datepicker-popper {
        margin-left: -50px;
    }

    .tr__multiselect {
        @extend %multiselect-filter;

        .tr__dropdown {
            &__label {
                border-bottom: 1px solid $bottom-border-gray;
            }
        }

        .tr__field {
            &.hidden {
                position: absolute;
            }

            &-input {
                border: none;
            }
        }
    }
}