@import 'styles/extends/_generics';

.tr__EndorsementChainModal {
    .content {
        .item_no_owner {
            .infoRow {
                .time {
                    color: $placeholder-gray;
                }
                .role {
                    margin-top: 5px;
                    font-size: 12px;
                    color: $placeholder-gray;
                }
                .id_account {
                    font-size: 12px;
                    margin-top: 5px;
                }
                .org {
                    font-size: 12px;
                    margin-top: 5px;
                }
                
            }
        }
    }
}