@import 'styles/extends/_generics';

.tr__tooltip {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        border: 5px solid transparent;
        border-bottom-color: $base-text-color;
        @include centered-position(true, false);
        bottom: -5px;
    }

    &--content {
        cursor: pointer;
    }

    &--popup {
        position: absolute;
        font-size: $small-size;
        background-color: $base-text-color;
        color: $white;
        padding: 10px 15px;
        border-radius: 3px;
        left: 0;
        top: calc(100% + 5px);
        line-height: 1.3;
        white-space: nowrap;
        z-index: 9;
        min-width: 100%;
        
        .tags {
            span {
                white-space: nowrap;
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            small {
                font-size: $tiny-size;
                @include opacity(0.5);
            }
        }
    }

    &:before,
    &--popup {
        @include opacity(0);
        visibility: hidden;
        @extend %enable-transition;
    }

    &.center {
        .tr__tooltip--popup {
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    &.right {
        .tr__tooltip--popup {
            right: 0;
            left: auto;
        }
    }

    &:hover {
        &:before, .tr__tooltip--popup {
            @include opacity(1);
            visibility: visible;
        }
    }

    .statistics {
        font-size: $small-size;

        p {
            &.label {
                @include opacity(0.5);
                text-transform: capitalize;
            }

            span {
                margin-left: 5px;
                color: $white;
            }
        }

        &--header {
            padding: 5px 5px 10px 5px;
            position: relative;
            .num {
                color: $white; 
            }

            &:after {
                content: '';
                position: absolute;
                width: calc(100% + 30px);
                left: -15px;
                bottom: 0;
                border-top: 1px solid $multiselect-gray;
                @include opacity(0.3);
            }
        }

        &--content {
            padding: 10px 5px 5px 5px;
        }

        &--item {
            margin-bottom: 10px;

            &:last-child {
                margin: 0;
            }
        }
    }

    &.hidden {
        &:hover {
            &:before, .tr__tooltip--popup {
                display: none;
            }
        }
    }
}