@import 'styles/extends/_generics';

$type-height : 150px;
$types-size : 50px;
$form-width: 700px;
$edge-radius: 8px;
$type-group-padding: 30px;

.tr__shipment-type {
    width: $form-width;
    border-radius: $edge-radius;
    overflow: hidden;
    box-shadow: $box-shadow;

    &--header {
        width: 100%;
        @extend %section-header;
    }

    &--content {
        background-color: $white;
        padding: $type-group-padding;
        
        h3 {
            font-size: $medium-size;
            color: $base-text-color;
            text-align: center;
        }
        
        button {
            width: calc(100% / 3 - 10px);
            height: $type-height;
            border-radius: $edge-radius;
            opacity: 0.3;
            filter: grayscale(1);
            background-color: transparent !important;
            border: 1px solid $base-text-color;
            

            &:hover {
                opacity: 1;
                filter: grayscale(0);
            }

            span {
                @include flex(column, center, flex-start, nowrap);

                img {
                    width: 100px;
                    height: 100px;
                }
                
                p {
                    color: $black;
                    font-size: $medium-size;
                }
            }
        }
    }
}